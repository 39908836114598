import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const NardwuarMustGo = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Nardwuar Must Go" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>
            Nardwuar Must Go; or, <br /> No More Rocking in the Free World
          </p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Jul. 19th, 2023) </p>
        </div>
        <div className="articleBody">
          <p>
            My mouth is bloody, it tastes like metal. My molar has come loose. I
            spit the bloody tooth on the ground. I’m tied to a chair in a dimly
            lit warehouse. My face is swollen and bruised. A figure in
            silhouette hovers over me. The only part of them that’s visible is a
            plaid golf cap. Dave Rowntree’s head is on a spike in the
            background.
          </p>
          <p>
            NARDWUAR: Say it. <br />
            ME: I won’t. <br />
            NARDWUAR: (demon voice - both falsetto and baritone, also
            auto-tuned) Say it!!!
          </p>
          <p>I spit blood onto his plaid loafers.</p>
          <p>
            I know everybody suspects “DJ” Vlad is a federal asset (and
            rightfully so) but I’m here to let y’all know that Nardwuar the
            Human Serviette is the most sinister character in all of music
            journalism. I have hard evidence that he is a wizard demon, and that
            his famous catchphrase is not just some quirk, but in fact a
            powerful incantation with which he steals the souls of artists and
            makes them do his bidding. When he utters “Doot Doola Doot Doo” and
            the artists reply “Doot Doo”, and then he freezes, what do you think
            he’s doing? He’s stealing the souls!!!{" "}
          </p>
          <p>
            And if you’re not yet grasping the gravity of what that means, let
            me put it in perspective for you. Jay Z said “Doot Doo”. Pharrell
            said “Doot Doo”. Lil Wayne said “Doot Doo”. E-40 said “Doot Doo”.
            Snoop Dogg said “Doot Doo” many times (and as Snoop Lion, no less).
            Young Thug, Future, Lil Uzi Vert said “Doot Doo”. Ed Sheeran said
            “Doot Doo”. Mac DeMarco, Clairo, Tame Impala, said “Doot Doo”. Drake
            and Kendrick Lamar said “Doot Doo”. Insane Clown Posse, Slipknot
            said “Doot Doo”. Hell, James Brown said “Doot Doo”!!! And the list
            goes on!!! Even Justin Trudeau and Mikhail Gorbachev said “Doot
            Doo”!!! This creature needs to be stopped…but how? And by who?{" "}
          </p>
          <p>
            I’m trapped in a warehouse.{" "}
            <a
              href="https://shoya.co/shoyaright/vol1/mt-shoya"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Shoyarights
            </a>{" "}
            will need reinforcements, Nardwuar has grown too powerful.{" "}
          </p>
          <p>
            {" "}
            CUT TO: Toro y Moi opening his window and sticking his head out on a
            sunny day, the birds are chirping. FREEZE FRAME.
          </p>
          <p>
            {" "}
            CUT TO: Bad Bunny getting his teeth cleaned at the dentist’s office.
            FREEZE FRAME.
          </p>
          <p>
            CUT TO: FKA Twigs using a selfie stick to record herself while doing
            fouettés in a room full of mirrors. FREEZE FRAME.
          </p>
          <p>
            CUT TO:{" "}
            <a
              href="https://shoya.co/shoyaright/vol2/street-zoology"
              target="__blank"
              rel="noopener noreferrer"
            >
              Sahbabii
            </a>{" "}
            struggling to open a Snickers wrapper, pausing to see if anyone’s
            looking, then continuing to struggle. FREEZE FRAME.
          </p>
          <p>
            CUT TO: Tierra Whack sitting alone at a chess board, making a move,
            then switching sides to play against herself. FREEZE FRAME.
          </p>
          <p>
            CUT TO: 100 gecs rooting for greyhounds racing around a track, when
            they cross the finish line Laura pouts and Dylan cheers. FREEZE
            FRAME.
          </p>
          <p>
            Silence in the warehouse, then: three{" "}
            <a
              href="https://shoya.co/shoyaright/vol3/shoyotathon-23"
              target="__blank"
              rel="noopener noreferrer"
            >
              Shoyota 9Runners
            </a>{" "}
            crash through the warehouse walls (like in that{" "}
            <a
              href="https://www.youtube.com/watch?v=g_qWhdxiR7k"
              target="_blank"
              rel="noopener noreferre"
            >
              new James Blake video
            </a>
            ) and all the musicians listed above hop out, guns drawn!!!!!!!
          </p>
          <p>
            NARDWUAR: (demon voice) How did you know where we were???! <br />
            MUSICIANS: (in unison) You’re Nardwuar, we have to know!!!
          </p>
          <p>
            They all empty their clips into Wizard Demon Nardwuar, countless
            shells littering the concrete floor. He explodes into
            grey-green-purple flames and then vanishes.
          </p>
          <p>ME: (sotto voce) Ba-boom.</p>
          <p>
            In all seriousness, Nardwuar is a legend!!! Anyway here go a handful
            of songs I’ve been listening to nonstop lately.{" "}
          </p>
          <p>
            <a
              href="https://www.youtube.com/watch?v=9FNXpNmh9lE"
              target="_blank"
              rel="noopener noreferrer"
            >
              Young Thug - Went Thru It
            </a>
            <br />
            "Now I keep a "S" on my chest, baby! I saved the world in a dress,
            baby!"
          </p>
          <p>
            <a
              href="https://www.youtube.com/watch?v=bEoaZc7pY4M"
              target="_blank"
              rel="noopener noreferrer"
            >
              George Harrison - I’d Have You Anytime
            </a>
            <br />
            "All I have is yours! All you see is mine!"
          </p>
          <p>
            <a
              href="https://www.youtube.com/watch?v=Hf487OncN9o"
              target="_blank"
              rel="noopener noreferrer"
            >
              Caroline Polacheck - Pretty In Possible
            </a>
            <br />
            "But down in the deep end! I can't be left alone!"
          </p>
          <p>
            <a
              href="https://www.youtube.com/watch?v=QzrFkI4QIqY"
              target="_blank"
              rel="noopener noreferrer"
            >
              Martin Rev - I Heard Your Name
            </a>
            <br />
            "I heard your name and I'll never be the same - I love you, yes I
            do! Now and forever!"
          </p>
          <p>
            <a
              href="https://www.youtube.com/watch?v=fK9hK82r-AM"
              target="_blank"
              rel="noopener noreferrer"
            >
              Frankie Smith - Double Dutch Bus{" "}
            </a>
            <br />
            "Izzo kizzay! Izzall rizzight! Izzo kizzay! Izzall rizzight!"
          </p>
          <p>Enjoy life! </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020 - &infin;</p>
        </div>
      </div>
    </div>
  )
}
export default NardwuarMustGo
